import { tensetAddress } from '..'

import { IconName } from '~/tenset-components'

const {
  Kanga,
  PancakeSwap,
  Gate,
  Discord,
  Telegram,
  Twitter,
  BitgetLogo,
  CoinWLogo,
  KakaoTalk,
  LineMono,
} = IconName

const usdtAddress = '0x55d398326f99059fF775485246999027B3197955'

export const tokensAvailableButtons = [
  {
    label: 'Pancake Swap',
    icon: PancakeSwap,
    link: `https://pancakeswap.finance/swap?inputCurrency=${usdtAddress}&outputCurrency=${tensetAddress}&chain=bsc`,
  },
  {
    label: 'Bitget',
    icon: BitgetLogo,
    link: 'https://www.bitget.com/spot/10SETUSDT',
  },
  {
    label: 'Gate',
    icon: Gate,
    link: 'https://www.gate.io/trade/10SET_USDT',
  },
  {
    label: 'CoinW',
    icon: CoinWLogo,
    link: 'https://www.coinw.com/frontweb/en_US/spot/10SETUSDT',
  },
  {
    label: 'Kanga Exchange',
    icon: Kanga,
    link: 'https://trade.kanga.exchange/market/10SET-USDT',
  },
]

export const socialButtons = [
  {
    icon: Twitter,
    link: 'https://twitter.com/Tenset_io',
    label: 'Follow on X',
  },
  {
    icon: Telegram,
    link: 'https://t.me/tensetio',
    label: 'Join Telegram',
  },
  {
    icon: Discord,
    link: 'https://discord.gg/PRavwRHubW',
    label: 'Join Discord',
  },
  {
    label: 'Join Kakao',
    link: 'https://open.kakao.com/o/gIQdeT2c',
    icon: KakaoTalk,
  },
  {
    label: 'Join Line',
    link: 'https://line.me/ti/g2/QBYVIHDNVY_InQzC-r7kjw',
    icon: LineMono,
  },
]
